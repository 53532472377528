<template>
  <v-card id="sectionalPerformanceCard" :loading="superLoading || superLoading">
    <v-card-title>
      <v-icon class="mr-2" color="deep-purple darken-4">
        mdi-clipboard-text-search
      </v-icon>

      <span class="mx-auto mx-md-1"> Sectional Performance : </span>

      <!-- Result Toggle Button -->
      <span v-if="reAttempt" class="mx-auto mx-md-1 mt-3 mt-md-0">
        <v-btn-toggle v-model="resultView" class="ml-3" mandatory>
          <v-btn value="original" small> Original </v-btn>

          <v-btn value="reAttempt" small> Re-attempt </v-btn>
        </v-btn-toggle>
      </span>
    </v-card-title>

    <v-card-text>
      <v-container fluid v-if="!loading || !superLoading">
        <v-simple-table v-if="sectionalData != null && testObj != null">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-center">Section</th>
                <th class="text-center">Score</th>
                <th class="text-center">Attempted</th>
                <th class="text-center">Correct</th>
                <th class="text-center">Accuracy</th>
                <th class="text-center">Time Spent</th>
              </tr>
            </thead>
            <tbody>
              <!-- Original Result Data -->
              <template v-if="resultView == 'original'">
                <tr
                  v-for="(sectionData, section) in sectionalData"
                  :key="section"
                >
                  <td class="text-center">{{ section }}</td>

                  <td class="text-center">
                    {{ sectionData.score }}/{{
                      testObj.selectedQuestions[section].length *
                      testObj.rewardPoints
                    }}
                  </td>

                  <td class="text-center">
                    {{ sectionData.attempted }}/{{
                      testObj.selectedQuestions[section].length
                    }}
                  </td>

                  <td class="text-center">
                    {{ sectionData.correct }}/ {{ sectionData.attempted }}
                  </td>

                  <td class="text-center">{{ sectionData.accuracy }}%</td>

                  <td class="text-center">{{ sectionData.timeSpent }} min</td>
                </tr>
              </template>

              <!-- ReAttempt Result Data -->
              <template v-else>
                <tr
                  v-for="(sectionData, section) in reAttemptSectionalData"
                  :key="section + '_reAttempt'"
                >
                  <td class="text-center">{{ section }}</td>

                  <td class="text-center">
                    {{ sectionData.score }}/{{
                      testObj.selectedQuestions[section].length *
                      testObj.rewardPoints
                    }}
                  </td>

                  <td class="text-center">
                    {{ sectionData.attempted }}/{{
                      testObj.selectedQuestions[section].length
                    }}
                  </td>

                  <td class="text-center">
                    {{ sectionData.correct }}/ {{ sectionData.attempted }}
                  </td>

                  <td class="text-center">{{ sectionData.accuracy }}%</td>

                  <td class="text-center">
                    {{ sectionData.timeSpent }} min
                  </td>
                </tr>
              </template>
            </tbody>
          </template>
        </v-simple-table>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "SectionalPerformanceCard",
  props: [
    "superLoading",
    "testObj",
    "resultObj",
    "allQuestions",
    "reAttempt",
    "reAttemptResultObj",
  ],
  data: () => ({
    loading: false,
    resultView: "original",
    sectionalData: null,
    reAttemptSectionalData: null,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },
    calculateOriginalData() {
      var sectionalData = {};

      //loop through selected questions of test
      Object.keys(this.testObj.selectedQuestions).forEach((section) => {
        //create section obj inside sectionalData
        sectionalData[section] = {
          score: 0,
          attempted: 0,
          correct: 0,
          accuracy: 0,
          timeSpent: 0,
        };

        sectionalData[section].timeSpent = (
          this.resultObj.sectionTimes[section].reduce((a, b) => a + b) / 60
        ).toFixed(2);

        var sectionQuestionsArr = this.testObj.selectedQuestions[section];

        sectionQuestionsArr.forEach((questionID) => {
          // evaluate score and attempted iff question is answered
          if (
            questionID in this.resultObj.selectedAnswers &&
            this.resultObj.questionState[questionID] == "answered"
          ) {
            sectionalData[section].attempted++;
            if (
              this.resultObj.selectedAnswers[questionID] ==
              this.allQuestions[questionID].correctAnswer
            ) {
              sectionalData[section].correct++;
              sectionalData[section].score += this.testObj.rewardPoints;
            } else {
              sectionalData[section].score -= this.testObj.punishmentPoints;
            }
          }
        });

        // update accuracy for the section
        var accuracy = (
          (sectionalData[section].correct / sectionalData[section].attempted) *
          100
        ).toFixed(2);
        sectionalData[section].accuracy = isNaN(accuracy) ? "0" : accuracy;
      });

      this.sectionalData = sectionalData;
    },

    calculateReAttemptData() {
      var sectionalData = {};

      //loop through selected questions of test
      Object.keys(this.testObj.selectedQuestions).forEach((section) => {
        //create section obj inside sectionalData
        sectionalData[section] = {
          score: 0,
          attempted: 0,
          correct: 0,
          accuracy: 0,
          timeSpent: 0,
        };

        sectionalData[section].timeSpent = (
          this.reAttemptResultObj.sectionTimes[section].reduce(
            (a, b) => a + b
          ) / 60
        ).toFixed(2);

        var sectionQuestionsArr = this.testObj.selectedQuestions[section];
        sectionQuestionsArr.forEach((questionID) => {
          // evaluate score and attempted iff question is answered
          if (
            questionID in this.reAttemptResultObj.selectedAnswers &&
            this.reAttemptResultObj.questionState[questionID] == "answered"
          ) {
            sectionalData[section].attempted++;
            if (
              this.reAttemptResultObj.selectedAnswers[questionID] ==
              this.allQuestions[questionID].correctAnswer
            ) {
              sectionalData[section].correct++;
              sectionalData[section].score += this.testObj.rewardPoints;
            } else {
              sectionalData[section].score -= this.testObj.punishmentPoints;
            }
          }
        });

        // update accuracy for the section
        var accuracy = (
          (sectionalData[section].correct / sectionalData[section].attempted) *
          100
        ).toFixed(2);
        sectionalData[section].accuracy = isNaN(accuracy) ? "0" : accuracy;
      });

      this.reAttemptSectionalData = sectionalData;
    },
  },

  mounted() {
    this.calculateOriginalData();

    if (this.reAttempt) {
      this.calculateReAttemptData();
    }

    this.loading = false;
  },
};
</script>