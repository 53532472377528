<template>
  <div id="test" class="fill-height indigo lighten-5 align-center">
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar">{{ snackbarText }}</v-snackbar>
    <!-- TODO: put loading, error, data state conditions -->

    <template v-if="loading">
      <h1 class="text-h1 text-center">Loading...</h1>
    </template>

    <template v-else-if="error">
      <v-alert type="error" text prominent>
        <h1 class="text-h1 text-center">{{ error }}</h1>
      </v-alert>
    </template>

    <template
      v-else-if="
        testObjLocal != null &&
        testScoresObjLocal != null &&
        testObjLocal.dataFetched == true
      "
    >
      <v-container>
        <v-row align="start" justify="center">
          <!-- Test Name Card and Re-attempt button -->
          <v-col cols="12" sm="12" class="px-0 px-md-3">
            <v-card>
              <v-card-title
                class="d-flex justify-center justify-md-start"
                style="text-transform: capitalize"
              >
                {{ resultObjLocal.studentData.name }} -
                {{ testObjLocal.testName }}
              </v-card-title>
            </v-card>
          </v-col>

          <v-col cols="12" sm="12" class="px-0 px-md-3">
            <OverallPerformanceCard
              :superLoading="loading"
              :testObj="testObjLocal"
              :resultObj="resultObjLocal"
              :allQuestions="allQuestionsLocal"
              :testScoresObj="testScoresObjLocal"
              :reAttempt="false"
            />
          </v-col>

          <v-col cols="12" sm="12" class="px-0 px-md-3">
            <SectionalPerformanceCard
              :superLoading="loading"
              :testObj="testObjLocal"
              :resultObj="resultObjLocal"
              :allQuestions="allQuestionsLocal"
              :reAttempt="reAttempt"
              :reAttemptResultObj="reAttemptResultObj"
            />
          </v-col>

          <v-col cols="12" sm="12" class="px-0 px-md-3">
            <SolutionCard
              :superLoading="loading"
              :testObj="testObjLocal"
              :resultObj="resultObjLocal"
              :allQuestions="allQuestionsLocal"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </div>
</template>

<script>
var dayjs = require("dayjs");
const isSameOrBefore = require("dayjs/plugin/isSameOrBefore");

dayjs.extend(isSameOrBefore);

import OverallPerformanceCard from "@/components/Home/View/Results/OverallPerformanceCard.vue";
import SectionalPerformanceCard from "@/components/Home/View/Results/SectionalPerformanceCard.vue";
import SolutionCard from "@/components/Home/View/Results/SolutionCard.vue";

export default {
  name: "Result",

  metaInfo: {
    title: "Result",
    meta: [
      {
        vmid: "description",
        name: "description",
        content: "Result Page description lorem ipsum dolor sit amet.",
      },
    ],
  },

  props: ["testObj", "resultObj", "allQuestions", "testScoresObj"],

  components: {
    OverallPerformanceCard,
    SectionalPerformanceCard,
    SolutionCard,
  },

  data: () => ({
    // Page State
    loading: true,
    discount: false,
    snackbar: false,
    snackbarText: "",
    error: "",
    currentWindow: "",
    instructionsLang: "en",
    testLang: "",
    testObjLocal: null,
    resultObjLocal: null,
    allQuestionsLocal: null, //all test questions with key id
    testScoresObjLocal: null,
    topFiveResults: [],
    reAttempt: false,
    reAttemptResultObj: null,
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
    },
    showSnackbar(text) {
      this.snackbarText = text;
      this.snackbar = true;
    },
    setError(val) {
      this.loading = false;
      this.error = val;
    },
  },

  mounted() {
    const testObj = this.testObj;
    const resultObj = this.resultObj;
    const allQuestionsObj = this.allQuestions;

    //check if props exists
    if (
      typeof testObj !== "object" ||
      testObj == null ||
      typeof resultObj !== "object" ||
      resultObj == null ||
      typeof allQuestionsObj !== "object" ||
      allQuestionsObj == null
    ) {
      this.$router.push("/404");
    } else {
      this.testObjLocal = this.testObj;
      this.$set(this.testObjLocal, "dataFetched", true);
      this.allQuestionsLocal = this.allQuestions;
      this.resultObjLocal = this.resultObj;
      this.testScoresObjLocal = this.testScoresObj;
      this.loading = false;
    }
  },
};
</script>

<style>
.htmlFromEditor img {
  max-width: 100% !important;
  height: auto;
}
</style>