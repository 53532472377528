<template>
  <v-card id="overallPerformanceCard" :loading="superLoading || superLoading">
    <v-card-title>
      <v-icon class="mr-2" color="deep-purple darken-3">
        mdi-chart-line-variant
      </v-icon>

      <span class="mx-auto mx-md-1"> Overall Performance : </span>

      <!-- Result Toggle Button -->
      <span v-if="reAttempt" class="mx-auto mx-md-1 mt-3 mt-md-0">
        <v-btn-toggle v-model="resultView" class="ml-3" mandatory>
          <v-btn value="original" small> Original </v-btn>

          <v-btn value="reAttempt" small> Re-attempt </v-btn>
        </v-btn-toggle>
      </span>
    </v-card-title>

    <v-card-text>
      <v-container fluid v-if="!loading || !superLoading">
        <!-- Original Result -->
        <v-row align="center" justify="center" v-if="resultView == 'original'">
          <!-- Rank -->
          <v-col cols="12" sm="6" md="3">
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-icon color="blue darken-2" size="50">
                  mdi-trophy-award
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-center text-md-left">
                <v-list-item-subtitle>Rank</v-list-item-subtitle>
                <v-list-item-title>{{
                  originalData.rankText
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Score -->
          <v-col cols="12" sm="6" md="3">
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-icon color="green darken-1" size="50">
                  mdi-sort-numeric-variant
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-center text-md-left">
                <v-list-item-subtitle>Score</v-list-item-subtitle>
                <v-list-item-title>{{
                  originalData.scoreText
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Attempts -->
          <v-col cols="12" sm="6" md="3">
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-icon color="pink darken-1" size="50">
                  mdi-spellcheck
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-center text-md-left">
                <v-list-item-subtitle>Attempts</v-list-item-subtitle>
                <v-list-item-title>{{
                  originalData.attemptsText
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Accuracy -->
          <v-col cols="12" sm="6" md="3">
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-icon color="deep-purple darken-1" size="50">
                  mdi-bullseye-arrow
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-center text-md-left">
                <v-list-item-subtitle>Accuracy</v-list-item-subtitle>
                <v-list-item-title>{{
                  originalData.accuracyText
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <!-- ReAttempt Result -->
        <v-row align="center" justify="center" v-else>
          <!-- Rank -->
          <v-col cols="12" sm="6" md="3">
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-icon color="blue darken-2" size="50">
                  mdi-trophy-award
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-center text-md-left">
                <v-list-item-subtitle>Rank</v-list-item-subtitle>
                <v-list-item-title>{{
                  reAttemptData.rankText
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Score -->
          <v-col cols="12" sm="6" md="3">
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-icon color="green darken-1" size="50">
                  mdi-sort-numeric-variant
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-center text-md-left">
                <v-list-item-subtitle>Score</v-list-item-subtitle>
                <v-list-item-title>{{
                  reAttemptData.scoreText
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Attempts -->
          <v-col cols="12" sm="6" md="3">
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-icon color="pink darken-1" size="50">
                  mdi-spellcheck
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-center text-md-left">
                <v-list-item-subtitle>Attempts</v-list-item-subtitle>
                <v-list-item-title>{{
                  reAttemptData.attemptsText
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <!-- Accuracy -->
          <v-col cols="12" sm="6" md="3">
            <v-list-item>
              <v-list-item-avatar size="50">
                <v-icon color="deep-purple darken-1" size="50">
                  mdi-bullseye-arrow
                </v-icon>
              </v-list-item-avatar>

              <v-list-item-content class="text-center text-md-left">
                <v-list-item-subtitle>Accuracy</v-list-item-subtitle>
                <v-list-item-title>{{
                  reAttemptData.accuracyText
                }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "OverallPerformanceCard",
  props: [
    "superLoading",
    "testObj",
    "resultObj",
    "allQuestions",
    "testScoresObj",
    "reAttempt",
    "reAttemptResultObj",
  ],
  data: () => ({
    loading: false,
    resultView: "original",
    originalData: {
      rankText: "",
      scoreText: "",
      attemptsText: "",
      accuracyText: "",
    },
    reAttemptData: {
      rankText: "",
      scoreText: "",
      attemptsText: "",
      accuracyText: "",
    },
  }),

  methods: {
    setLoading(value) {
      this.loading = value;
      this.$emit("setSuperLoading", value);
    },

    // Note: Both can be done in one loop
    calcOriginalData() {
      // rank
      if (typeof this.testScoresObj !== "object" || !this.testScoresObj) {
        return;
      }

      const sortedtestScoresArr = Object.keys(this.testScoresObj).sort(
        (a, b) => this.testScoresObj[b] - this.testScoresObj[a]
      );

      var totalStudents = 0;
      var rank = 0;

      sortedtestScoresArr.forEach((studentUID) => {
        totalStudents++;
        if (studentUID == this.resultObj.studentUID) {
          rank = totalStudents;
        }
      });

      this.$set(this.originalData, "rankText", `${rank}/${totalStudents}`);

      // Score
      const maxScore = this.testObj.questionCount * this.testObj.rewardPoints;
      this.$set(
        this.originalData,
        "scoreText",
        `${this.resultObj.score}/${maxScore}`
      );

      // Attempts and Accuracy
      var attempted = 0;
      var correct = 0;

      Object.keys(this.resultObj.questionState).forEach((questionID) => {
        if (this.resultObj.questionState[questionID] == "answered") {
          attempted++;
          if (
            this.resultObj.selectedAnswers[questionID] ==
            this.allQuestions[questionID].correctAnswer
          ) {
            correct++;
          }
        }
      });

      var accuracy = (correct / attempted) * 100;
      accuracy = isNaN(accuracy) ? "0%" : `${accuracy.toFixed(2)}%`;

      this.$set(
        this.originalData,
        "attemptsText",
        `${attempted}/${this.testObj.questionCount}`
      );

      this.$set(this.originalData, "accuracyText", accuracy);
    },
  },

  mounted() {
    this.loading = false;
    this.calcOriginalData();
  },
};
</script>